.profiles-with-tandem--show .RaSimpleShowLayout {
    padding: 0;
}

.profiles-with-tandem--show .RaSimpleShowLayout-row {
    border-bottom: 0 !important;
}

.tandem-management {
    display: flex;
    gap: 30px;
}

.tandem-management .description {
    color: #767676;
    margin-bottom: 18px;
    height: 50px;
}

div.tandem-management > div {
    padding: 20px 30px;
    border-radius: 10px;
    flex: 1;
}

.tandem-management .profile {
    background-color: #f7f7f7;
}

.tandem-management .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
}

.tandem-management .line {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.tandem-management .value {
    display: flex;
    align-items: center;
}

.line.profile-name {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
}

.tandem-management .line.profile-name > p {
    font-weight: 700;
    margin: 0;
}

.tandem-management .line > span {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
}

.tandem-management .line > span.label {
    width: 150px;
}

.tandem-table th {
    font-weight: 700;
}

.tandem-table .chips-box {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tandem-actions {
    display: flex;
    gap: 10px;
    margin: 15px 0;
}
