/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

.profiles-with-tandem-list .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #ffffff !important;
}

.profiles-with-tandem-list .line {
    height: 64px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.profiles-with-tandem-list .line.tandem.profile {
    padding-left: 10px;
    min-width: 200px;
}

.profiles-with-tandem-list th {
    padding: 6px;
}

.profiles-with-tandem-list .line:last-of-type {
    border-bottom: 0;
}

.profiles-with-tandem-list .MuiTableCell-root.MuiTableCell-paddingCheckbox {
    padding-top: 20px !important;
    vertical-align: top !important;
}

.profiles-with-tandem-list .MuiTable-root.RaDatagrid-table {
    border-collapse: separate;
    border-spacing: 0 60px;
}

.profiles-with-tandem-list .MuiTableRow-root.RaDatagrid-row {
    margin-bottom: 50px;
}

.profiles-with-tandem-list .MuiTableCell-root:not(th) {
    border-bottom: 1px solid #000000 !important;
}

.profiles-with-tandem-list .MuiTableCell-root.RaDatagrid-rowCell {
    padding: 0;
}

.profiles-with-tandem-list .MuiTableHead-root.RaDatagrid-thead {
    border-collapse: collapse !important;
}
