/**
 *
 *   Copyright ou © ou Copr. Université de Lorraine, (2025)
 *
 *   Direction du Numérique de l'Université de Lorraine - SIED
 *
 *   Ce logiciel est un programme informatique servant à rendre accessible
 *   sur mobile et sur internet l'application ULEP (University Language
 *   Exchange Programme) aux étudiants et aux personnels des universités
 *   parties prenantes.
 *
 *   Ce logiciel est régi par la licence CeCILL 2.1, soumise au droit français
 *   et respectant les principes de diffusion des logiciels libres. Vous pouvez
 *   utiliser, modifier et/ou redistribuer ce programme sous les conditions
 *   de la licence CeCILL telle que diffusée par le CEA, le CNRS et INRIA
 *   sur le site "http://cecill.info".
 *
 *   En contrepartie de l'accessibilité au code source et des droits de copie,
 *   de modification et de redistribution accordés par cette licence, il n'est
 *   offert aux utilisateurs qu'une garantie limitée. Pour les mêmes raisons,
 *   seule une responsabilité restreinte pèse sur l'auteur du programme, le
 *   titulaire des droits patrimoniaux et les concédants successifs.
 *
 *   À cet égard, l'attention de l'utilisateur est attirée sur les risques
 *   associés au chargement, à l'utilisation, à la modification et/ou au
 *   développement et à la reproduction du logiciel par l'utilisateur étant
 *   donné sa spécificité de logiciel libre, qui peut le rendre complexe à
 *   manipuler et qui le réserve donc à des développeurs et des professionnels
 *   avertis possédant des connaissances informatiques approfondies. Les
 *   utilisateurs sont donc invités à charger et à tester l'adéquation du
 *   logiciel à leurs besoins dans des conditions permettant d'assurer la
 *   sécurité de leurs systèmes et/ou de leurs données et, plus généralement,
 *   à l'utiliser et à l'exploiter dans les mêmes conditions de sécurité.
 *
 *   Le fait que vous puissiez accéder à cet en-tête signifie que vous avez
 *   pris connaissance de la licence CeCILL 2.1, et que vous en avez accepté les
 *   termes.
 *
 */

.profiles-with-tandem--show .RaSimpleShowLayout {
    padding: 0;
}

.profiles-with-tandem--show .RaSimpleShowLayout-row {
    border-bottom: 0 !important;
}

.tandem-management {
    display: flex;
    gap: 30px;
}

.tandem-management .description {
    color: #767676;
    margin-bottom: 18px;
    height: 50px;
}

div.tandem-management > div {
    padding: 20px 30px;
    border-radius: 10px;
    flex: 1;
}

.tandem-management .profile {
    background-color: #f7f7f7;
}

.tandem-management .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
}

.tandem-management .line {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.tandem-management .value {
    display: flex;
    align-items: center;
}

.line.profile-name {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
}

.tandem-management .line.profile-name > p {
    font-weight: 700;
    margin: 0;
}

.tandem-management .line > span {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
}

.tandem-management .line > span.label {
    width: 150px;
}

.tandem-table th {
    font-weight: 700;
}

.tandem-table .chips-box {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tandem-actions {
    display: flex;
    gap: 10px;
    margin: 15px 0;
}
